import React from 'react';
import { CustomButtonComponent } from './ui';
import { BlogSlider } from '.';
import { URLS } from '../utils';

const BlogSection = () => {
  return (
    <section
      id="blog"
      className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-gray-100 to-gray-200 py-16 px-8"
    >
      <h2 className="text-4xl md:text-5xl font-bold text-gray-800 mb-12">Блог</h2>

      <BlogSlider />

      <CustomButtonComponent size="large" href={URLS.BLOG}>
        Смотреть все
      </CustomButtonComponent>
    </section>
  );
};

export default BlogSection;
