import React from 'react';
import { Helmet } from 'react-helmet-async';
import { BlogContent, PageLayout } from '../components';

export const crumbsBlogPage = [{ label: 'Главная', path: '/' }, { label: 'Блог' }];

const BlogPage = () => {
  return (
    <>
      <Helmet>
        <title>Blog - Zennovaton - IT company</title>
        <meta name="description" content="Читайте последние статьи на нашем блоге" />
        <meta name="keywords" content="блог, статьи, новости" />
      </Helmet>
      <PageLayout>
        <BlogContent />
      </PageLayout>
    </>
  );
};

export default BlogPage;
