import React from 'react';
import { ArticleDates } from '.';
import { BACKEND_URL, decodeHtmlEntities } from '../utils';
import { marked } from 'marked';

const SingleArticleContent = ({ post }) => {
  if (!post?.attributes) return null;

  const { image, title, publishedAt, updatedAt, description, fullText } = post.attributes;

  return (
    <>
      <ArticleDates publishedAt={publishedAt} updatedAt={updatedAt} />
      <div className="w-full bg-white rounded-2xl mb-4">
        <img
          src={`${BACKEND_URL}${image?.data?.attributes?.formats?.large?.url}`}
          alt={image?.data?.attributes?.alternativeText ?? title}
          className="w-full h-auto max-h-[720px] rounded-2xl object-cover"
          style={{
            objectFit: 'cover',
            maxWidth: '100%',
            maxHeight: '720px',
          }}
        />
      </div>
      <div className="mb-8 text-gray-500 italic">{description}</div>
      <div className="mt-8 single-article" dangerouslySetInnerHTML={{ __html: marked(decodeHtmlEntities(fullText)) }} />
    </>
  );
};

export default SingleArticleContent;
