import React from 'react';
import { Link } from 'react-router-dom';
import { BACKEND_URL, URLS } from '../utils';

const NewsCardMain = ({ image, title, description, id, fullText }) => {
  return (
    <div className="bg-white h-full p-[10px]  480:pb-[24px] 0:pb-[72px] rounded-lg">
      <div className="h-full flex flex-col">
        <Link to={`${URLS.BLOG_SINGLE}/${id}/`} className="h-full flex flex-col ">
          {image ? (
            <img
              src={`${BACKEND_URL}${image}`}
              alt={title}
              className="object-cover object-center mb-5 w-[100%] rounded-xl h-[200px] md:h-[354px]"
              width={360}
              height={360}
            />
          ) : (
            <p>No Image</p>
          )}
          <div className="flex flex-col justify-between">
            <h2 className="font-semibold text-base">{title}</h2>
            <p className="text-sm mt-2 truncated" style={{ '--rows': 2 }}>
              {description}
            </p>
            <p className="text-sm mt-2 mb-9 truncated" style={{ '--rows': 6 }}>
              {fullText}
            </p>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default NewsCardMain;
