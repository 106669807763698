import React from 'react';
import { Loading, TitlePage } from './ui';
import { CardBlog } from '.';
import { crumbsBlogPage } from '../pages/BlogPage';
import { useBlogPosts } from '../hooks';

const BlogContent = () => {
  const { posts, isLoading } = useBlogPosts();

  return (
    <section
      id="services"
      className="min-h-screen flex flex-col items-center bg-gradient-to-r from-gray-100 to-gray-200 py-28"
    >
      <TitlePage text={'Блог'} crumbs={crumbsBlogPage} />
      {isLoading ? (
        <div className="mx-auto mb-6 md:mb-12">
          <Loading />
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 w-full max-w-5xl px-4">
          {posts.map(({ id, attributes: { title, image, description, fullText } }) => (
            <CardBlog
              key={id}
              id={id}
              title={title}
              image={image?.data?.attributes?.formats?.medium?.url}
              description={description}
              fullText={fullText}
            />
          ))}
        </div>
      )}
    </section>
  );
};

export default BlogContent;
