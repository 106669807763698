import { useState, useEffect } from 'react';
import { API_BLOG_SINGLE_URL } from '../utils';

const useBlogPost = (id) => {
  const [post, setPost] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchGetPost = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(API_BLOG_SINGLE_URL(id));
        const result = await response.json();
        const postData = result.data;
        setPost(postData);
      } catch (error) {
        console.error('Ошибка при загрузке данных:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchGetPost();
  }, [id]);

  return { post, isLoading };
};

export default useBlogPost;
