// import React from 'react';
// import CustomButtonComponent from './CustomButtonComponent';
// import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import TelegramIcon from '@mui/icons-material/Telegram';
// import WhatsAppIcon from '@mui/icons-material/WhatsApp';

// const ContactSection = () => {
//   return (
//     <div id="contact" className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-gray-100 to-gray-300 p-8">
//       <h2 className="text-4xl md:text-5xl font-bold text-gray-800 mb-8 text-center">Свяжитесь с нами</h2>

//       <div className="w-full max-w-4xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
//         <div className="flex flex-col justify-center items-start bg-white shadow-lg rounded-lg p-6 md:p-8">
//           <h3 className="text-2xl font-semibold text-gray-700 mb-4">Наши контакты:</h3>
//           <p className="text-gray-600 mb-4">Телефон (РБ): +375445235052</p>
//           <p className="text-gray-600 mb-4">Телефон (РФ): +79801073492</p>
//           <p className="text-gray-600 mb-4">Электронная почта: sup@z-nn.site</p>
//           <div className="flex justify-center mt-4 space-x-4 w-full">
//             <a href="https://www.linkedin.com/company/znn/about/?viewAsMember=true" target="_blank" rel="noopener noreferrer" className="bg-blue-700 text-white p-3 rounded-full shadow-lg hover:bg-blue-800 transition-colors">
//               <LinkedInIcon />
//             </a>
//             <a href="https://t.me/ZENNOVATION" target="_blank" rel="noopener noreferrer" className="bg-blue-500 text-white p-3 rounded-full shadow-lg hover:bg-blue-600 transition-colors">
//               <TelegramIcon />
//             </a>
//             <a href="https://wa.me/79801073492" target="_blank" rel="noopener noreferrer" className="bg-green-500 text-white p-3 rounded-full shadow-lg hover:bg-green-600 transition-colors">
//               <WhatsAppIcon />
//             </a>
//           </div>
//         </div>

//         <div className="flex flex-col justify-center items-start bg-white shadow-lg rounded-lg p-6 md:p-8">
//           <h3 className="text-2xl font-semibold text-gray-700 mb-4">Ваше сообщение</h3>
//           <form className="w-full">
//             <input
//               type="text"
//               placeholder="Ваше имя"
//               className="w-full mb-4 p-3 bg-gray-100 rounded-md focus:outline-none"
//               style={{ boxShadow: 'none' }}
//               onFocus={(e) => e.target.style.boxShadow = '0 0 0 2px rgba(129, 206, 160, 0.3)'}
//               onBlur={(e) => e.target.style.boxShadow = 'none'}
//             />
//             <input
//               type="email"
//               placeholder="Ваш Email"
//               className="w-full mb-4 p-3 bg-gray-100 rounded-md focus:outline-none"
//               style={{ boxShadow: 'none' }}
//               onFocus={(e) => e.target.style.boxShadow = '0 0 0 2px rgba(129, 206, 160, 0.3)'}
//               onBlur={(e) => e.target.style.boxShadow = 'none'}
//             />
//             <textarea
//               placeholder="Ваше сообщение"
//               className="w-full mb-4 p-3 bg-gray-100 rounded-md focus:outline-none h-32"
//               style={{ boxShadow: 'none' }}
//               onFocus={(e) => e.target.style.boxShadow = '0 0 0 2px rgba(129, 206, 160, 0.3)'}
//               onBlur={(e) => e.target.style.boxShadow = 'none'}
//             />
//             <div className="flex justify-center">
//               <CustomButtonComponent type="submit">
//                 Отправить сообщение
//               </CustomButtonComponent>
//             </div>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ContactSection;

import React, { useState } from 'react';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TelegramIcon from '@mui/icons-material/Telegram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { TitleBlock, CustomButtonComponent } from './ui';

const ContactSection = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitted(false);
    setError(null);

    try {
      const response = await fetch('https://api.z-nn.site/api/applications', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          data: formData,
        }),
      });

      if (response.ok) {
        setIsSubmitted(true);
        setFormData({
          name: '',
          email: '',
          message: '',
        });
      } else {
        const result = await response.json();
        setError(result.error.message || 'Ошибка при отправке сообщения');
      }
    } catch (error) {
      setError('Ошибка при отправке сообщения');
    }
  };

  return (
    <div
      id="contact"
      className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-gray-100 to-gray-300 px-8 py-20"
    >
      <TitleBlock title={'Свяжитесь с нами'} />

      <div className="w-full max-w-4xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="flex flex-col justify-center items-start bg-white shadow-lg rounded-lg p-6 md:p-8">
          <h3 className="text-2xl font-semibold text-gray-700 mb-4">Наши контакты:</h3>
          <p className="text-gray-600 mb-4">
            Телефон (РБ):
            <a href="tel:+375445235052" className="text-gray-900 hover:text-gray-600 transition-colors">
              +375445235052
            </a>
          </p>
          <p className="text-gray-600 mb-4">
            Телефон (РФ):
            <a href="tel:+79801073492" className="text-gray-900 hover:text-gray-600 transition-colors">
              +79801073492
            </a>
          </p>
          <p className="text-gray-600 mb-4">
            Электронная почта:
            <a href="mailto:sup@z-nn.site" className="text-gray-900 hover:text-gray-600 transition-colors">
              sup@z-nn.site
            </a>
          </p>
          <div className="flex justify-center mt-4 space-x-4 w-full">
            <a
              href="https://www.linkedin.com/company/znn/about/?viewAsMember=true"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-blue-700 text-white p-3 rounded-full shadow-lg hover:bg-blue-800 transition-colors"
            >
              <LinkedInIcon />
            </a>
            <a
              href="https://t.me/ZENNOVATION"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-blue-500 text-white p-3 rounded-full shadow-lg hover:bg-blue-600 transition-colors"
            >
              <TelegramIcon />
            </a>
            <a
              href="https://wa.me/79801073492"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-green-500 text-white p-3 rounded-full shadow-lg hover:bg-green-600 transition-colors"
            >
              <WhatsAppIcon />
            </a>
          </div>
        </div>

        <div className="flex flex-col justify-start items-start bg-white shadow-lg rounded-lg p-6 md:p-8">
          <h3 className="text-2xl font-semibold text-gray-700 mb-4">Ваше сообщение</h3>
          {isSubmitted ? (
            <p className="text-green-600 text-center">Сообщение успешно отправлено!</p>
          ) : (
            <form className="w-full" onSubmit={handleSubmit}>
              <input
                type="text"
                name="name"
                placeholder="Ваше имя"
                value={formData.name}
                onChange={handleInputChange}
                className="w-full mb-4 p-3 bg-gray-100 rounded-md focus:outline-none"
                style={{ boxShadow: 'none' }}
                onFocus={(e) => (e.target.style.boxShadow = '0 0 0 2px rgba(129, 206, 160, 0.3)')}
                onBlur={(e) => (e.target.style.boxShadow = 'none')}
              />
              <input
                type="email"
                name="email"
                placeholder="Ваш Email"
                value={formData.email}
                onChange={handleInputChange}
                className="w-full mb-4 p-3 bg-gray-100 rounded-md focus:outline-none"
                style={{ boxShadow: 'none' }}
                onFocus={(e) => (e.target.style.boxShadow = '0 0 0 2px rgba(129, 206, 160, 0.3)')}
                onBlur={(e) => (e.target.style.boxShadow = 'none')}
              />
              <textarea
                name="message"
                placeholder="Ваше сообщение"
                value={formData.message}
                onChange={handleInputChange}
                className="w-full mb-4 p-3 bg-gray-100 rounded-md focus:outline-none h-32 resize-none"
                style={{ boxShadow: 'none' }}
                onFocus={(e) => (e.target.style.boxShadow = '0 0 0 2px rgba(129, 206, 160, 0.3)')}
                onBlur={(e) => (e.target.style.boxShadow = 'none')}
              />
              {error && <p className="text-red-600 mb-4">{error}</p>}
              <div className="flex justify-center">
                <CustomButtonComponent type="submit">Отправить сообщение</CustomButtonComponent>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
