import { useState, useEffect } from 'react';
import { API_BLOG_URL } from '../utils';

const useBlogPosts = (limit = null) => {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchGetPosts = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(API_BLOG_URL);
        const result = await response.json();
        const postsData = result.data;

        if (limit) {
          setPosts(postsData.slice(0, limit));
        } else {
          setPosts(postsData);
        }
      } catch (error) {
        console.error('Ошибка при загрузке данных:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchGetPosts();
  }, [limit]);

  return { posts, isLoading };
};

export default useBlogPosts;
