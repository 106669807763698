import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { MainPage, BlogPage, ArticlePage } from './pages';
import { ScrollToTop } from './components';
import { URLS } from './utils';
import './index.css';

const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path={URLS.MAIN} element={<MainPage />} />
        <Route path={URLS.BLOG} element={<BlogPage />} />
        <Route path={`${URLS.BLOG_SINGLE}/:id/`} element={<ArticlePage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
