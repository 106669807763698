import albaImage from '../assets/testimonials/alba.webp';
import amirImage from '../assets/testimonials/amir.jpg';
import nikolaiImage from '../assets/testimonials/nikolai.png';

const testimonials = [
  {
    id: 1,
    name: 'Альба Фигуэрас',
    content: 'Отличная поддержка и быстрый сервис! Я очень довольна итоговым результатом.',
    position: 'Co-founder & COO',
    company: 'Her-age',
    borderColor: 'rgba(193, 148, 68, 0.3)',
    photo: albaImage,
  },
  {
    id: 2,
    name: 'Амир Хаддади',
    content:
      'Очень профессиональный подход и отличные результаты. Работать с Денисом и его командой одно удовольствие.',
    position: 'IT project specialist',
    company: 'Piceci Services',
    borderColor: 'rgba(95, 111, 170, 0.3)',
    photo: amirImage,
  },
  {
    id: 3,
    name: 'Николай Иванов',
    content: 'Рекомендую! Быстрая работа и отличное качество - это все про ZNN.',
    position: 'Предприниматель',
    company: 'Transavto',
    borderColor: 'rgba(129, 206, 160, 0.3)',
    photo: nikolaiImage,
  },
];

export default testimonials;
