import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { URLS } from '../utils';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isMobile = useMediaQuery('(max-width:768px)');
  const [hovered, setHovered] = useState(false);
  const navigate = useNavigate();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);

    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    } else {
      navigate(URLS.MAIN, { state: { targetSection: sectionId } });
    }
  };

  const letters = hovered ? 'ZENNOVATION'.split('') : 'ZNN'.split('');

  return (
    <header className="fixed top-0 left-1/2 transform -translate-x-1/2 w-11/12 bg-white bg-opacity-90 backdrop-blur-md shadow-lg rounded-b-xl z-50">
      <AppBar position="static" style={{ backgroundColor: 'transparent', boxShadow: 'none', borderRadius: 'inherit' }}>
        <Toolbar onMouseEnter={() => !isMobile && setHovered(true)} onMouseLeave={() => !isMobile && setHovered(false)}>
          <img src="/znn-sticker.png" alt="ZNN Logo" className="h-12 logo-animation" /> {/* Логотип */}
          <Typography
            variant="h5"
            className={`flex-grow text-[#021E2A] font-bold ${!isMobile ? 'animated-text' : ''}`}
            style={{ fontFamily: 'Arial, sans-serif', whiteSpace: 'nowrap' }}
          >
            {letters.map((letter, index) => (
              <span
                key={index}
                className={`letter ${hovered && !isMobile ? 'expand' : 'shrink'}`}
                style={{ animationDelay: `${index * 0.1}s` }}
              >
                {letter}
              </span>
            ))}
          </Typography>
          {isMobile ? (
            <>
              <IconButton edge="start" style={{ color: '#81CEA0' }} aria-label="menu" onClick={handleMenu}>
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    handleClose();
                    scrollToSection('about');
                  }}
                  className="text-[#7e7f9a] hover:text-[#81CEA0] "
                >
                  <span className="nav-link">О нас</span>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    scrollToSection('services');
                  }}
                  className="text-[#7e7f9a] hover:text-[#81CEA0]"
                >
                  <span className="nav-link">Услуги</span>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    scrollToSection('cases');
                  }}
                  className="text-[#7e7f9a] hover:text-[#81CEA0]"
                >
                  <span className="nav-link">Кейсы</span>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    scrollToSection('team');
                  }}
                  className="text-[#7e7f9a] hover:text-[#81CEA0]"
                >
                  <span className="nav-link">Команда</span>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    scrollToSection('blog');
                  }}
                  className="text-[#7e7f9a] hover:text-[#81CEA0]"
                >
                  <span className="nav-link">Блог</span>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    scrollToSection('testimonials');
                  }}
                  className="text-[#7e7f9a] hover:text-[#81CEA0]"
                >
                  <span className="nav-link">Отзывы</span>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    scrollToSection('contacts');
                  }}
                  className="text-[#7e7f9a] hover:text-[#81CEA0]"
                >
                  <span className="nav-link">Контакты</span>
                </MenuItem>
              </Menu>
            </>
          ) : (
            <nav className="flex space-x-4">
              <span
                onClick={() => {
                  handleClose();
                  scrollToSection('about');
                }}
                className="nav-link"
              >
                О нас
              </span>
              <span
                onClick={() => {
                  handleClose();
                  scrollToSection('services');
                }}
                className="nav-link"
              >
                Услуги
              </span>
              <span
                onClick={() => {
                  handleClose();
                  scrollToSection('cases');
                }}
                className="nav-link"
              >
                Кейсы
              </span>
              <span
                onClick={() => {
                  handleClose();
                  scrollToSection('team');
                }}
                className="nav-link"
              >
                Команда
              </span>
              <span
                onClick={() => {
                  handleClose();
                  scrollToSection('blog');
                }}
                className="nav-link"
              >
                Блог
              </span>
              <span
                onClick={() => {
                  handleClose();
                  scrollToSection('testimonials');
                }}
                className="nav-link"
              >
                Отзывы
              </span>
              <span
                onClick={() => {
                  handleClose();
                  scrollToSection('contact');
                }}
                className="nav-link"
              >
                Контакты
              </span>
            </nav>
          )}
        </Toolbar>
      </AppBar>
    </header>
  );
};

export default Header;
