import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { TitleBlock } from './ui';
import { cases } from '../datas';

const CasesSection = () => {
  return (
    <section
      id="cases"
      className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-r from-gray-100 to-gray-200 py-16"
    >
      <TitleBlock title={'Кейсы'} />
      <div className="w-full max-w-4xl px-4">
        <Carousel indicators={true} animation="slide" navButtonsAlwaysVisible={true} className="h-full">
          {cases.map((item, index) => (
            <div
              key={index}
              className="flex flex-col items-center justify-center bg-white shadow-lg rounded-xl py-6 px-10 md:py-8 md:px-20"
              style={{
                backgroundColor: item.backgroundColor,
                minHeight: '700px',
                width: '100%',
                maxWidth: '1600px',
                margin: '0 auto',
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
              }}
            >
              <h3 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4 text-center">{item.name}</h3>
              <p className="text-base md:text-lg text-gray-700 mb-6 text-center">{item.description}</p>
            </div>
          ))}
        </Carousel>
      </div>
    </section>
  );
};

export default CasesSection;
