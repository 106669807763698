import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  AboutSection,
  ServicesSection,
  CasesSection,
  TeamSection,
  BlogSection,
  TestimonialsSection,
  ContactSection,
  PageLayout,
} from '../components';

const MainPage = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.state?.targetSection) {
      const section = document.getElementById(location.state.targetSection);

      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return (
    <PageLayout>
      <AboutSection />
      <ServicesSection />
      <CasesSection />
      <TeamSection />
      <BlogSection />
      <TestimonialsSection />
      <ContactSection />
    </PageLayout>
  );
};

export default MainPage;
