import React, { useState } from 'react';
import { Modal } from '.';
import { services } from '../datas';
import { TitleBlock } from './ui';

const ServicesSection = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', details: '' });

  const openModal = (title, details) => {
    setModalContent({ title, details });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <section
      id="services"
      className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-r from-gray-100 to-gray-200 py-16"
    >
      <TitleBlock title={'Наши услуги'} />
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 w-full max-w-5xl px-4">
        {services.map((service, index) => (
          <div
            key={index}
            className="flex flex-col items-center justify-center text-center p-6 bg-gray-100 bg-opacity-50 shadow-lg rounded-lg transform transition-transform duration-5000 ease-in-out md:hover:scale-105"
            style={{
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
              transition:
                'background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, transform 0.3s ease-in-out',
              borderColor: service.hoverColor,
              borderWidth: '2px',
              borderStyle: 'solid',
              height: '100%',
              minHeight: '250px',
            }}
            onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = service.hoverColor)}
            onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.5)')}
          >
            <div className="flex flex-col items-center justify-center flex-grow">
              <h3 className="text-xl md:text-2xl font-bold text-gray-900 mb-3">{service.title}</h3>
              <p className="text-md text-gray-700 mb-6 text-balance">{service.description}</p>
            </div>
            <div>
              <button
                onClick={() => openModal(service.title, service.details)}
                className="px-4 py-2 text-white font-semibold bg-gray-800 rounded-full hover:bg-gray-700 transition-colors"
              >
                Узнать больше
              </button>
            </div>
          </div>
        ))}
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal} title={modalContent.title} details={modalContent.details} />
    </section>
  );
};

export default ServicesSection;
