import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import { TitleBlock } from './ui';
import { testimonials } from '../datas';

const TestimonialsSection = () => {
  const [current, setCurrent] = useState(0);

  const nextTestimonial = () => {
    setCurrent((prev) => (prev === testimonials.length - 1 ? 0 : prev + 1));
  };

  const prevTestimonial = () => {
    setCurrent((prev) => (prev === 0 ? testimonials.length - 1 : prev - 1));
  };

  return (
    <div
      id="testimonials"
      className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-gray-100 to-gray-200 py-16 px-4"
    >
      <TitleBlock title={'Отзывы'} />

      <div className="relative w-full max-w-5xl mx-auto">
        <div
          className="flex flex-col items-center text-center py-6 px-10 md:py-8 md:px-20 bg-white shadow-2xl rounded-3xl"
          style={{ minHeight: '420px' }}
        >
          <Avatar
            alt={testimonials[current].name}
            src={testimonials[current].photo}
            sx={{
              width: { xs: 100, sm: 120, md: 140 },
              height: { xs: 100, sm: 120, md: 140 },
              border: `4px solid ${testimonials[current].borderColor}`,
              marginBottom: '16px',
              transition: 'border-color 0.3s ease-in-out',
            }}
            imgProps={{ draggable: false }}
          />
          <p className="text-xl md:text-3xl text-gray-700 italic mb-4 md:mb-6">"{testimonials[current].content}"</p>
          <h3 className="text-2xl md:text-3xl font-semibold text-gray-900">{testimonials[current].name}</h3>
          <p className="text-lg md:text-2xl text-gray-600">{testimonials[current].position}</p>
          <p className="text-base md:text-xl text-gray-500 italic">{testimonials[current].company}</p>
        </div>

        <button
          onClick={prevTestimonial}
          className="absolute left-6 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-800 transition text-3xl md:text-4xl"
        >
          &larr;
        </button>
        <button
          onClick={nextTestimonial}
          className="absolute right-6 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-800 transition text-3xl md:text-4xl"
        >
          &rarr;
        </button>
      </div>
    </div>
  );
};

export default TestimonialsSection;
