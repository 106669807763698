import React from 'react';
import { Breadcrumbs } from '.';

const TitlePage = ({ text, crumbs }) => {
  return (
    <div className="bm-12 w-full max-w-5xl px-4 mb-10">
      <h1 className="text-4xl md:text-6xl font-bold text-gray-800 mb-4">{text}</h1>

      {crumbs && <Breadcrumbs crumbs={crumbs} />}
    </div>
  );
};

export default TitlePage;
