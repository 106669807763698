import React from 'react';
import loadingGif from '../../assets/loading.gif';

const Loading = () => {
  return (
    <div className="flex justify-center items-start mt-4">
      <img src={loadingGif} alt="Loading..." className="w-[100px] h-[100px]" />
    </div>
  );
};

export default Loading;
