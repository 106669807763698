import denisImage from '../assets/team/den.jpg';
import evgenyImage from '../assets/team/jek.jpg';
import kateImage from '../assets/team/kate.jpg';
import germanImage from '../assets/team/ger.jpg';
import dimaImage from '../assets/team/dima.jpg';

const teamMembers = [
  {
    id: 1,
    name: 'Денис',
    position: 'Lead Full-Stack Engineer',
    professionalExperience: 'Специализируется на разработке высоконагруженных веб-приложений и микросервисов',
    avatar: denisImage,
  },
  {
    id: 2,
    name: 'Женя',
    position: 'Lead Dev-ops Engineer',
    professionalExperience: 'Эксперт в автоматизации CI/CD процессов и оркестрации контейнеров.',
    avatar: evgenyImage,
  },
  {
    id: 3,
    name: 'Катя',
    position: 'Lead Shopify expert & Frontend developer',
    professionalExperience: 'Опытный разработчик с акцентом на Shopify и создание пользовательских тем и приложений.',
    avatar: kateImage,
  },
  {
    id: 4,
    name: 'Герман',
    position: 'Sales & account manager',
    professionalExperience:
      'Успешно управляет клиентскими аккаунтами и развивает продажи, обеспечивая рост выручки компании.',
    avatar: germanImage,
  },
  {
    id: 5,
    name: 'Дима',
    position: 'Frontend developer',
    professionalExperience: 'Разработчик в создании адаптивных пользовательских интерфейсов.',
    avatar: dimaImage,
  },
  {
    id: 6,
    name: '+20 сотрудников по всему миру',
    position: '',
    professionalExperience: '',
    avatar: null,
  },
];

export default teamMembers;
