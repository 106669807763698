import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import { NewsCardMain, NewsCardMainColumn, SliderNavigation } from '.';
import { Loading } from './ui';
import { useBlogPosts } from '../hooks';

const BlogSlider = () => {
  const { posts, isLoading } = useBlogPosts(4);

  if (isLoading) {
    return (
      <div className="mx-auto mb-6 md:mb-12">
        <Loading />
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8 max-w-6xl mx-auto mb-6 md:mb-12">
      <Swiper
        modules={[Autoplay, Navigation]}
        navigation={{ nextEl: '.news-slider-next', prevEl: '.news-slider-prev' }}
        spaceBetween={20}
        slidesPerView={1}
        grabCursor={true}
        className="max-w-[100%] h-auto overflow-hidden relative"
        direction="horizontal"
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        loop={true}
      >
        {posts.map(({ id, attributes }, index) => {
          const { title, image, description, fullText } = attributes;
          const imageUrl = image?.data?.attributes?.formats?.medium?.url;

          return (
            <SwiperSlide key={index} className="relative h-100% flex-shrink-0">
              <div className="max-w-[100%] h-full">
                <NewsCardMain id={id} title={title} image={imageUrl} description={description} fullText={fullText} />
              </div>
            </SwiperSlide>
          );
        })}
        <SliderNavigation prevUniqueClassName="news-slider-prev" nextUniqueClassName="news-slider-next" />
      </Swiper>

      <div className="flex flex-col gap-5">
        {posts.map(({ id, attributes }) => {
          const { title, image, description } = attributes;
          const smallImageUrl = image?.data?.attributes?.formats?.small?.url;

          return <NewsCardMainColumn key={id} image={smallImageUrl} title={title} id={id} description={description} />;
        })}
      </div>
    </div>
  );
};

export default BlogSlider;
