import React from 'react';
import { formatDate } from '../utils';

const ArticleDates = ({ publishedAt, updatedAt }) => {
  return (
    <div className="flex justify-between items-center gap-2 mb-4">
      <div className="text-lg text-gray-500 flex flex-wrap justify-start">
        <span className="text-sm md:text-lg text-gray-700 mr-1 text-left">Дата публикации:</span>
        <span className="text-sm md:text-lg text-gray-500 text-left">
          {formatDate(publishedAt ?? 'Дата не указана')}
        </span>
      </div>
      <div className="flex flex-wrap justify-end gap-[2px]">
        <span className="text-sm md:text-lg text-gray-700 mr-1 text-right">Дата обновления:</span>
        <span className="text-sm md:text-lg text-gray-500 text-right">
          {formatDate(updatedAt ?? 'Дата не указана')}
        </span>
      </div>
    </div>
  );
};

export default ArticleDates;
