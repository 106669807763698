import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumbs = ({ crumbs }) => {
  return (
    <ol className="list-reset flex flex-wrap">
      {crumbs.map((crumb, index) => (
        <li key={index} className="flex items-center">
          {crumb.path ? (
            <Link to={crumb.path} className="text-lg text-gray-600 transition ease-out md:hover:text-gray-400">
              {crumb.label}
            </Link>
          ) : (
            <span className="text-lg text-gray-400">{crumb.label}</span>
          )}
          {index < crumbs.length - 1 && <span className="mx-2">/</span>}
        </li>
      ))}
    </ol>
  );
};

export default Breadcrumbs;
