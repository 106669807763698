const services = [
  {
    title: 'Разработка сайта',
    description: 'Мы создаем современные, адаптивные и функциональные веб-сайты под ключ.',
    hoverColor: 'rgba(129, 206, 160, 0.3)',
    details: `
      Что входит в услугу:
      - Анализ требований:
        - Встреча с клиентом для обсуждения бизнес-целей и задач.
        - Определение целевой аудитории и её потребностей.
        - Сбор и анализ требований, включая функциональные и нефункциональные.
      - Прототипирование:
        - Создание wireframes для определения структуры страниц.
        - Разработка интерактивных прототипов для оценки навигации и пользовательского опыта.
        - Итерации и согласования с клиентом.
      - Дизайн:
        - Создание уникального и визуально привлекательного дизайна, соответствующего бренду.
        - Разработка цветовой схемы, типографики и графических элементов.
        - Обеспечение адаптивности дизайна для различных устройств и экранов.
      - Разработка:
        - Верстка макетов с использованием HTML, CSS, JavaScript, обеспечивая кроссбраузерную совместимость.
        - Разработка серверной части с использованием современных технологий (например, Node.js, PHP, Ruby on Rails).
        - Интеграция с системами управления контентом (CMS) и внешними API.
      - Тестирование:
        - Функциональное тестирование для проверки всех возможностей сайта.
        - Тестирование производительности для обеспечения быстрого времени отклика.
        - Тестирование на различных устройствах и браузерах для кроссплатформенной совместимости.
      - Запуск:
        - Развертывание сайта на хостинге клиента или предоставление услуг хостинга.
        - Обучение сотрудников клиента работе с системой управления сайтом.
        - Предоставление документации и технической поддержки на этапе запуска.`,
  },
  {
    title: 'Разработка программного обеспечения',
    description: 'Создание высококачественного программного обеспечения и приложений.',
    hoverColor: 'rgba(193, 148, 68, 0.3)',
    details: `
      Что входит в услугу:
      - Анализ задач:
        - Определение ключевых задач, которые должен решать программный продукт.
        - Сбор и документирование требований, включая функциональные спецификации и нефункциональные ограничения.
      - Проектирование архитектуры:
        - Разработка архитектуры системы, включая выбор подходящих технологий и паттернов проектирования.
        - Моделирование базы данных и определение ключевых сущностей.
        - Планирование модульности и масштабируемости приложения.
      - Разработка:
        - Разработка модулей и компонентов системы согласно архитектурному плану.
        - Регулярные ревью кода и тестирование модулей для обеспечения высокого качества.
        - Интеграция с внешними сервисами и API.
      - Тестирование:
        - Юнит-тестирование для проверки отдельных модулей.
        - Интеграционное тестирование для проверки взаимодействия модулей.
        - Регрессионное тестирование после внесения изменений.
      - Внедрение:
        - Развертывание программного обеспечения в продакшн-среде.
        - Обучение конечных пользователей и предоставление документации.
        - Оказание технической поддержки в период внедрения.
      - Поддержка:
        - Регулярные обновления программного обеспечения для обеспечения его актуальности.
        - Мониторинг производительности и доступности.
        - Реагирование на запросы пользователей и устранение возникших проблем.`,
  },
  {
    title: 'Разработка мобильных приложений и PWA',
    description: 'Создание мобильных приложений и прогрессивных веб-приложений (PWA) для любых платформ.',
    hoverColor: 'rgba(95, 111, 170, 0.3)',
    details: `
      Что входит в услугу:
      - Идея и планирование:
        - Определение ключевых функций приложения на основе требований клиента.
        - Анализ целевой аудитории и конкурентного окружения.
        - Создание дорожной карты проекта, включая временные рамки и этапы разработки.
      - Проектирование интерфейса:
        - Создание прототипов интерфейса для визуализации пользовательского опыта.
        - Проектирование навигации и интерфейсов, ориентированных на удобство пользователя.
        - Итерации и тестирование прототипов с пользователями.
      - Разработка:
        - Разработка нативных мобильных приложений для iOS и Android с использованием Swift, Kotlin, или других подходящих технологий.
        - Создание PWA с использованием современных фронтенд-фреймворков (например, React, Angular, Vue.js).
        - Интеграция с бэкендом, API, и сторонними сервисами.
      - Тестирование:
        - Тестирование производительности и отзывчивости приложения на различных устройствах.
        - Проверка совместимости с различными версиями операционных систем.
        - Автоматизированное и ручное тестирование для выявления и исправления ошибок.
      - Публикация:
        - Подготовка приложения к публикации в App Store и Google Play.
        - Оптимизация приложения для магазинов приложений (ASO).
        - Выпуск PWA с поддержкой всех основных функций, включая офлайн-режим.
      - Поддержка и обновления:
        - Обновление приложения с учетом изменений в операционных системах.
        - Внедрение новых функций и улучшение существующих на основе пользовательского фидбэка.
        - Мониторинг производительности и оперативное исправление багов.`,
  },
  {
    title: 'Поддержка и доработка вашего продукта',
    description: 'Мы оказываем услуги по поддержке и развитию вашего существующего программного продукта.',
    hoverColor: 'rgba(193, 148, 68, 0.3)',
    details: `
      Что входит в услугу:
      - Регулярные обновления:
        - Обеспечение актуальности программного обеспечения с учетом изменений в законодательстве и нормативных актах.
        - Внедрение обновлений безопасности для защиты от новых угроз.
        - Обновление зависимостей и библиотек для обеспечения стабильности работы системы.
      - Оптимизация:
        - Анализ производительности программного обеспечения и выявление узких мест.
        - Внедрение оптимизаций для улучшения скорости и уменьшения использования ресурсов.
        - Обновление архитектуры для улучшения масштабируемости и гибкости системы.
      - Добавление новых функций:
        - Анализ потребностей бизнеса и внедрение новых возможностей в программный продукт.
        - Планирование и разработка новых модулей или функций.
        - Интеграция новых возможностей с существующей системой.
      - Исправление ошибок:
        - Оперативное выявление и исправление критических багов.
        - Мониторинг логов и пользовательских отзывов для выявления и устранения проблем.
        - Обеспечение стабильной и бесперебойной работы системы.`,
  },
  {
    title: 'Аудит безопасности',
    description: 'Проводим комплексный аудит безопасности для выявления уязвимостей в ваших системах.',
    hoverColor: 'rgba(95, 111, 170, 0.3)',
    details: `
      Что входит в услугу:
      - Проверка на уязвимости:
        - Проведение тестирования на проникновение для выявления слабых мест в системе.
        - Анализ безопасности сетевой инфраструктуры и серверов.
        - Проверка соответствия системы требованиям безопасности, таким как GDPR или ISO 27001.
      - Анализ риска:
        - Оценка потенциального ущерба от возможных угроз и уязвимостей.
        - Разработка плана по минимизации рисков и устранению критических уязвимостей.
        - Создание рекомендаций по улучшению текущих мер безопасности.
      - Рекомендации по усилению защиты:
        - Внедрение передовых практик по защите данных и предотвращению утечек.
        - Настройка и оптимизация систем безопасности, таких как файрволы, системы обнаружения вторжений (IDS), и антивирусные решения.
        - Подготовка отчета по результатам аудита с подробным описанием всех выявленных уязвимостей и предложениями по их устранению.`,
  },
  {
    title: 'SEO продвижение и ведение соц сетей',
    description: 'Комплексное SEO продвижение и профессиональное ведение социальных сетей для вашего бизнеса.',
    hoverColor: 'rgba(129, 206, 160, 0.3)',
    details: `
      Что входит в услугу:
      - Аудит сайта:
        - Анализ текущего состояния сайта с точки зрения SEO: проверка индексации, структуры ссылок, контента, метаданных и скорости загрузки.
        - Выявление слабых мест и составление плана по их улучшению.
      - Разработка стратегии продвижения:
        - Определение ключевых слов и фраз, которые помогут привлечь целевую аудиторию.
        - Создание контент-плана с учетом сезонности и актуальных трендов.
        - Планирование и внедрение кампаний в социальных сетях для увеличения охвата и вовлеченности.
      - SEO-оптимизация:
        - Оптимизация контента сайта для повышения его релевантности поисковым запросам.
        - Работа с внутренней перелинковкой и структурой сайта для улучшения индексации.
        - Внедрение технических изменений, таких как улучшение скорости загрузки страниц и исправление ошибок.
      - Ведение аккаунтов в социальных сетях:
        - Создание и публикация регулярного контента, который отражает ценности бренда и интересы аудитории.
        - Проведение рекламных кампаний в социальных сетях для привлечения новой аудитории.
        - Мониторинг и анализ эффективности кампаний с последующей корректировкой стратегии.`,
  },
];

export default services;
