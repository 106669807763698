import React from 'react';
import Avatar from '@mui/material/Avatar';
import { TitleBlock } from './ui';
import { teamMembers } from '../datas';

const TeamSection = () => {
  return (
    <div
      id="team"
      className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-gray-100 to-gray-200 py-16 px-8"
    >
      <TitleBlock title={'Команда'} />

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
        {teamMembers.map((member) => (
          <div
            key={member.id}
            className={`relative flex flex-col items-center text-center p-8 bg-white shadow-lg rounded-lg transform transition-transform duration-500 ease-in-out md:hover:scale-105 `}
          >
            {member.avatar ? (
              <Avatar
                alt={member.name}
                src={member.avatar}
                sx={{
                  width: 100,
                  height: 100,
                  marginBottom: '16px',
                  transition: 'box-shadow 0.3s ease-in-out transform 0.3s ease-in-out',
                }}
                onMouseEnter={(e) => (e.currentTarget.style.boxShadow = `0 0 10px ${member.borderColor}`)}
                onMouseLeave={(e) => (e.currentTarget.style.boxShadow = 'none')}
              />
            ) : (
              <div className="relative w-full h-full flex items-center justify-center">
                <div className="flex flex-col items-center gradient-text-container">
                  <span className="text-6xl md:text-9xl font-bold text-black leading-none text-right gradient-text">
                    20+
                  </span>
                  <div className="text-xl md:text-3xl text-black mt-4 flex flex-col justify-center text-center gradient-text">
                    <div className="text-balance">Сотрудников по всему миру</div>
                  </div>
                </div>
              </div>
            )}
            {member.avatar && (
              <>
                <h3 className="text-xl font-semibold text-gray-900">{member.name}</h3>
                <p className="text-gray-600">{member.position}</p>
                <p className="text-gray-600 mt-4 text-sm md:text-base text-balance">{member.professionalExperience}</p>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeamSection;
