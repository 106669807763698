import React, { useState, useEffect } from 'react';
import Lottie from 'lottie-react';
import abstractAnimation from '../assets/Animation - 1723556240142.json';
import { CustomButtonComponent } from './ui';

const AboutSection = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => setIsVisible(entry.isIntersecting), { threshold: 0.1 });

    observer.observe(document.getElementById('about'));
    return () => observer.disconnect();
  }, []);

  return (
    <section
      id="about"
      className="relative min-h-screen flex items-center justify-center bg-gradient-to-r from-gray-100 to-gray-200 py-12 md:py-0"
    >
      {isVisible && (
        <Lottie
          animationData={abstractAnimation}
          loop={true}
          style={{ willChange: 'transform, opacity' }}
          className="absolute inset-0 w-full h-full object-cover z-0 opacity-100"
        />
      )}

      <div className="relative z-10 backdrop-blur-lg bg-white bg-opacity-30 border border-white border-opacity-30 text-black py-16 px-10 rounded-lg w-full max-w-4xl mx-auto shadow-2xl">
        <div className="flex flex-col items-center text-center">
          <h2 className="text-4xl md:text-5xl font-bold mb-6">Между дзеном и инновациями</h2>
          <p className="text-lg md:text-xl mb-8">
            Zennovation (ZNN) — Мы объединяем передовые технологии с гармоничным подходом к разработке, создавая
            решения, которые работают эффективно и стабильно. Наша команда специалистов обладает богатым опытом в
            разработке коммерческих решений и опенсорс технологий. Мы успешно реализуем проекты по дизайну и созданию
            веб-сайтов, веб-инструментов, виджетов и мобильных приложений разной сложности, помогая нашим клиентам
            достигать их целей с помощью современных и надежных IT-решений или просто делать мир лучше.
          </p>
          <CustomButtonComponent size="large" href="#services">
            Ознакомиться с нашими услугами
          </CustomButtonComponent>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
