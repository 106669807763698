import React from 'react';

const SliderNavigation = ({ nextUniqueClassName, prevUniqueClassName }) => {
  return (
    <div className="flex w-fit absolute right-[12px] bottom-[12px] z-[2] gap-[12px]">
      <div
        className={`${prevUniqueClassName} prev group rounded-[50%] cursor-pointer md:hover:bg-[#e0e0e0] md:hover:shadow-[0px_6px_12px_rgba(0,0,0,0.15)] transition-all duration-200`}
      >
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="16" cy="16" r="16" fill="#F4F6FB" />
          <path
            d="M18.5 21L13.5 16L18.5 11"
            stroke="#333333"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>

      <div
        className={`${nextUniqueClassName} next group rounded-[50%] cursor-pointer md:hover:bg-[#e0e0e0] md:hover:shadow-[0px_6px_12px_rgba(0,0,0,0.15)] transition-all duration-200`}
      >
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="16" cy="16" r="16" fill="#F4F6FB" />
          <path
            d="M13.5 21L18.5 16L13.5 11"
            stroke="#333333"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </div>
  );
};

export default SliderNavigation;
