const cases = [
  {
    name: 'Shopify CSV Аггрегатор',
    description:
      'Это встроенное приложение Shopify стало настоящим прорывом в автоматизации работы с каталогами. Оно позволяет агрегировать CSV файлы, предлагая мощные инструменты, такие как редактор, паттернматчер, конвертер и AI для интеллектуального анализа и автоматизации импорта и экспорта данных. В разработке использовались передовые технологии Fastify и Remix.js, которые обеспечивают высокую производительность и невероятную гибкость. Это приложение полностью изменило способ работы с каталогами в Shopify, позволяя пользователям экономить время и ресурсы, а также улучшать точность данных.',
    backgroundColor: 'rgba(129, 206, 160, 0.3)',
  },
  {
    name: 'Агрегатор люксовых авто',
    description:
      'Уникальный агрегатор люксовых автомобилей для аренды, который был создан с использованием новейших технологий Next.js и Nest.js. Этот сайт не просто помогает пользователям находить и арендовать автомобили, он предлагает умные функции подбора, учитывающие предпочтения пользователя, историю запросов и доступность автомобилей в реальном времени. Проект демонстрирует, как можно интегрировать современные веб-технологии для создания высококачественного пользовательского опыта, который отвечает самым высоким требованиям. Благодаря своему интерфейсу и продуманным алгоритмам, агрегатор стал важным инструментом для любителей роскошных автомобилей.',
    backgroundColor: 'rgba(95, 111, 170, 0.3)',
  },
  {
    name: 'Госпортал с системой баллов',
    description:
      'Этот государственный портал представляет собой инновационное решение для вовлечения граждан в общественные мероприятия. С использованием технологий Strapi и Next.js, мы создали платформу, которая не только обеспечивает удобный доступ к государственным услугам, но и мотивирует пользователей с помощью системы баллов. Участие в мероприятиях поощряется начислением баллов, которые затем можно обменивать на различные награды и привилегии. Это решение стало ключевым элементом в стратегии вовлечения граждан и демонстрирует, как современные технологии могут быть использованы для повышения гражданской активности и создания более вовлеченного общества.',
    backgroundColor: 'rgba(193, 148, 68, 0.3)',
  },
];

export default cases;
