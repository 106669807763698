import React from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/system';

const CustomButton = styled(Button)({
  backgroundColor: '#ffffff',
  color: '#000000',
  padding: '10px 20px',
  borderRadius: '50px',
  textTransform: 'none',
  fontWeight: 'bold',
  transition: 'background-color 0.3s ease, transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
  flexShrink: '0',
  '&:hover': {
    backgroundColor: '#e0e0e0',
    boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.15)',
  },
  '&:active': {
    transform: 'scale(0.95)',
    backgroundColor: 'rgba(193, 148, 68, 0.3)',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  },
});

const CustomButtonComponent = ({ children, ...props }) => {
  return <CustomButton {...props}>{children}</CustomButton>;
};

export default CustomButtonComponent;
