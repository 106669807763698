import React from 'react';
import { Link } from 'react-router-dom';
import { BACKEND_URL, URLS } from '../utils';

const CardBlog = ({ image, title, description, id }) => {
  return (
    <div className="bg-white h-auto p-[10px] rounded-lg shadow-lg transform transition-transform duration-5000 ease-in-out md:hover:scale-105">
      <Link to={`${URLS.BLOG_SINGLE}/${id}/`} className="mx-auto flex flex-col gap-[16px]">
        <div className="relative">
          {image ? (
            <img
              src={`${BACKEND_URL}${image}`}
              alt={title}
              className="block object-cover object-center rounded-xl w-full h-[200px]"
              width={295}
              height={200}
            />
          ) : (
            <p className="object-cover object-center rounded-xl min-w-[150px] max-w-[150px] h-[100px] md:min-w-[170px] md:max-w-[170px] md:h-[128px] mr-4">
              No Image
            </p>
          )}
        </div>
        <div className="flex flex-col justify-between mb-2">
          <h3 className="font-semibold text-base">{title}</h3>
          <p className="text-sm mt-2 truncated" style={{ '--rows': 6 }}>
            {description}
          </p>
        </div>
      </Link>
    </div>
  );
};

export default CardBlog;
