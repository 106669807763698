import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { PageLayout, SingleArticleContent } from '../components';
import { useBlogPost } from '../hooks';
import { Loading, TitlePage } from '../components/ui';
import { URLS } from '../utils';

const ArticlePage = () => {
  const { id } = useParams();
  const { post, isLoading } = useBlogPost(id);
  const [crumbs, setCrumbs] = useState([]);

  useEffect(() => {
    if (post?.attributes) {
      setCrumbs([
        { label: 'Главная', path: URLS.MAIN },
        { label: 'Блог', path: URLS.BLOG },
        { label: post.attributes.title || 'Загрузка...' },
      ]);
    }
  }, [id, post]);

  return (
    <>
      <Helmet>
        <title>{post?.attributes?.title ? `${post.attributes.title}` : 'Blog - Zennovaton - IT company'}</title>
        <meta name="description" content={post?.attributes?.description || 'Читайте последние статьи на нашем блоге'} />
        <meta name="keywords" content="блог, статьи, новости" />
      </Helmet>
      <PageLayout>
        <section className="min-h-screen flex flex-col items-center bg-gradient-to-r from-gray-100 to-gray-200 pt-28 pb-20">
          {isLoading ? (
            <div className="mx-auto mb-6 md:mb-12">
              <Loading />
            </div>
          ) : (
            <>
              <TitlePage text={post?.attributes?.title} crumbs={crumbs} />
              <div className="bm-12 w-full max-w-5xl px-4">
                <SingleArticleContent post={post} />
              </div>
            </>
          )}
        </section>
      </PageLayout>
    </>
  );
};

export default ArticlePage;
