import React from 'react';
import { Link } from 'react-router-dom';
import { BACKEND_URL, URLS } from '../utils';

const NewsCardMainColumn = ({ image, title, description, id }) => {
  return (
    <div className="bg-white h-auto p-[10px] rounded-lg shadow-lg transform transition-transform duration-5000 ease-in-out md:hover:scale-105">
      <Link to={`${URLS.BLOG_SINGLE}/${id}/`} className="mx-auto flex">
        <div className="relative">
          {image ? (
            <img
              src={`${BACKEND_URL}${image}`}
              alt={title}
              className="block object-cover object-center rounded-xl min-w-[150px] max-w-[150px] h-[100px] md:min-w-[170px] md:max-w-[170px] md:h-[128px] mr-4"
              width={150}
              height={100}
            />
          ) : (
            <p className="object-cover object-center rounded-xl min-w-[150px] max-w-[150px] h-[100px] md:min-w-[170px] md:max-w-[170px] md:h-[128px] mr-4">
              No Image
            </p>
          )}
        </div>
        <div className="flex flex-col justify-between h-30">
          <div>
            <h3 className="font-semibold text-base">{title.length > 60 ? `${title.slice(0, 60)}...` : title}</h3>
            <p className="text-sm mt-2 truncated" style={{ '--rows': 2 }}>
              {description}
            </p>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default NewsCardMainColumn;
